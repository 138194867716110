import React, { useContext, useState, useEffect, useRef } from "react";

import { ScreenContainer, Tab, Button, SelectField, Box, TextField, Modal, FileUpload, Message, Badge } from '../../components';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { useHistory } from 'react-router-dom';
import { get, post, getAuthToken, getDocumentUrl, getUrl} from '../../server/api';
import { useForm } from 'react-hook-form';
import getStatus from '../../utils/statusMachine';

function Machines() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ successMsg, setSuccessMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const [search, setSearch] = useState('');
    const [company, setCompany] = useState('');
    const [subsidiary, setSubsidiary] = useState('');
    const [site, setSite] = useState('');
    const [downloadSite, setDownloadSite] = useState('');
    const [family, setFamily] = useState('');
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [product, setProduct] = useState('');
    const [year, setYear] = useState('');
    const [parkNumber, setParkNumber] = useState('');
    const [status, setStatus] = useState('');
    const [char_filters, setCharFilters] = useState('');
    const [type_filters, setTypeFilters] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);

    const [companies, setCompanies] = useState([{label: Text('select', lang), value: ''}]);
    const [subsidiaries, setSubsidiaries] = useState([{label: Text('select', lang), value: ''}]);
    const [sites, setSites] = useState([{label: Text('select', lang), value: ''}]);

    const [familyFilters, setFamilyFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [brandFilters, setBrandFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [modelFilters, setModelFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [productFilters, setProductFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [yearFilters, setYearFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [parkNumberFilters, setParkNumberFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [statusFilters, setStatusFilters] = useState([{label: Text('select', lang), value: ''}]);

    const history = useHistory();
    const modalRef = useRef();
    const modalDownloadRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const loadFilters = async () => {
           
            const data = {
                company,
                subsidiary,
                site,
                all: true,
                light: true,
                char_filters,
                type_filters
            }

            let dataCompanies = await get('/companies', data);
            if(dataCompanies && dataCompanies.success){
                let newCompanies = [{value: '', label: Text('select', lang)}];
                dataCompanies.items.forEach(element => {
                    newCompanies.push({value: element.id, label: element.name})
                });
                setCompanies(newCompanies);
            }
            let dataSubsidiaries = await get('/subsidiaries', data);
            if(dataSubsidiaries && dataSubsidiaries.success){
                let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                dataSubsidiaries.items.forEach(element => {
                    newSubsidiaries.push({value: element.id, label: element.name})
                });
                setSubsidiaries(newSubsidiaries);
            }
            let dataSites = await get('/sites', data);
            if(dataSites && dataSites.success){
                let newSites = [{value: '', label: Text('select', lang)}];
                dataSites.items.forEach(element => {
                    newSites.push({value: element.id, label: element.name})
                });
                setSites(newSites);
            }
            

            const params = {
                company: menu.context === 'TOPAZ' ? company : menu.company,
                subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary,
                site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site,
                search,
                family,
                brand,
                model,
                product,
                park_number: parkNumber,
                year,
                status,
                char_filters,
                type_filters
            }

            const result = await get('/machine/filters', params);

            if(result.success){
                let filters = result.data;

                if(filters.hasOwnProperty('families')){
                    let newFamilyFilters = [{label: Text('select', lang), value: ''}];

                    filters.families.forEach((family) => {
                        newFamilyFilters.push(family);
                    })

                    setFamilyFilters(newFamilyFilters);
                }

                if(filters.hasOwnProperty('brands')){
                    let newBrandFilters = [{label: Text('select', lang), value: ''}];

                    filters.brands.forEach((brand) => {
                        newBrandFilters.push(brand);
                    })

                    setBrandFilters(newBrandFilters);
                }

                if(filters.hasOwnProperty('models')){
                    let newModelFilters = [{label: Text('select', lang), value: ''}];

                    filters.models.forEach((city) => {
                        newModelFilters.push(city);
                    })

                    setModelFilters(newModelFilters);
                }
                
                if(filters.hasOwnProperty('products')){
                    let newProductFilters = [{label: Text('select', lang), value: ''}];

                    filters.products.forEach((item) => {
                        newProductFilters.push(item);
                    })

                    setProductFilters(newProductFilters);
                }

                if(filters.hasOwnProperty('years')){
                    let newYearsFilters = [{label: Text('select', lang), value: ''}];

                    filters.years.forEach((item) => {
                        newYearsFilters.push(item);
                    })

                    setYearFilters(newYearsFilters);
                }

                if(filters.hasOwnProperty('park_numbers')){
                    let newParkNumbersFilters = [{label: Text('select', lang), value: ''}];

                    filters.park_numbers.forEach((item) => {
                        newParkNumbersFilters.push(item);
                    })

                    setParkNumberFilters(newParkNumbersFilters);
                }

                if(filters.hasOwnProperty('status')){
                    let newStatusFilters = [{label: Text('select', lang), value: ''}];

                    filters.status.forEach((item) => {
                        newStatusFilters.push(item);
                    })


                    setStatusFilters(newStatusFilters);
                }

            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }

            setIsLoading2(false);
        }

       loadFilters();
        
    }, [family, brand, model, site, subsidiary, product, company, year, parkNumber, status, search, menu, char_filters])

    useEffect(() => {
        const loadFilters = async () => {
            
            let data = {
                company,
                subsidiary,
                site,
                all: true,
                light: true,
                char_filters,
                type_filters
            }

            if( type_filters === "site"){

                let dataSites = await get('/sites', data);
                if(dataSites && dataSites.success){
                    let newSites = [{value: '', label: Text('select', lang)}];
                    dataSites.items.forEach(element => {
                        newSites.push({value: element.id, label: element.name})
                    });
                    setSites(newSites);
                }

            }

            if( type_filters === "subsidiary"){
                
                let dataSubsidiaries = await get('/subsidiaries', data);
                if(dataSubsidiaries && dataSubsidiaries.success){
                    let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                    dataSubsidiaries.items.forEach(element => {
                        newSubsidiaries.push({value: element.id, label: element.name})
                    });
                    setSubsidiaries(newSubsidiaries);
                }

                
            }


            if( type_filters === "company"){
                let dataCompanies = await get('/companies', data);
                if(dataCompanies && dataCompanies.success){
                    let newCompanies = [{value: '', label: Text('select', lang)}];
                    dataCompanies.items.forEach(element => {
                        newCompanies.push({value: element.id, label: element.name})
                    });
                    setCompanies(newCompanies);
                }
            }
            
            switch(type_filters) {
                case "company" : 
                    setSite('')
                    setSubsidiary('');
                    break;
                case "subsidiary" :
                    setSite('');
                    break;
                default:
                    break;
            }
            

            if ( type_filters === "brand" || type_filters === "model" || type_filters === "product" || type_filters === "park_number" || type_filters === "year" || type_filters === "status" || type_filters === "family"){
                const params = {
                    company: menu.context === 'TOPAZ' ? company : menu.company,
                    subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary,
                    site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site,
                    search,
                    family,
                    brand,
                    model,
                    product,
                    park_number: parkNumber,
                    year,
                    status,
                    char_filters,
                    type_filters
                }

                const result = await get('/machine/filters', params);

                if(result.success){
                    let filters = result.data;

                    if(filters.hasOwnProperty('families')){
                        let newFamilyFilters = [{label: Text('select', lang), value: ''}];

                        filters.families.forEach((family) => {
                            newFamilyFilters.push(family);
                        })

                        setFamilyFilters(newFamilyFilters);
                    }

                    if(filters.hasOwnProperty('brands')){
                        let newBrandFilters = [{label: Text('select', lang), value: ''}];

                        filters.brands.forEach((brand) => {
                            newBrandFilters.push(brand);
                        })

                        setBrandFilters(newBrandFilters);
                    }

                    if(filters.hasOwnProperty('models')){
                        let newModelFilters = [{label: Text('select', lang), value: ''}];

                        filters.models.forEach((city) => {
                            newModelFilters.push(city);
                        })

                        setModelFilters(newModelFilters);
                    }
                    
                    if(filters.hasOwnProperty('products')){
                        let newProductFilters = [{label: Text('select', lang), value: ''}];

                        filters.products.forEach((item) => {
                            newProductFilters.push(item);
                        })

                        setProductFilters(newProductFilters);
                    }

                    if(filters.hasOwnProperty('years')){
                        let newYearsFilters = [{label: Text('select', lang), value: ''}];

                        filters.years.forEach((item) => {
                            newYearsFilters.push(item);
                        })

                        setYearFilters(newYearsFilters);
                    }

                    if(filters.hasOwnProperty('park_numbers')){
                        let newParkNumbersFilters = [{label: Text('select', lang), value: ''}];

                        filters.park_numbers.forEach((item) => {
                            newParkNumbersFilters.push(item);
                        })

                        setParkNumberFilters(newParkNumbersFilters);
                    }

                    if(filters.hasOwnProperty('status')){
                        let newStatusFilters = [{label: Text('select', lang), value: ''}];

                        filters.status.forEach((item) => {
                            newStatusFilters.push(item);
                        })


                        setStatusFilters(newStatusFilters);
                    }

                } else {
                    if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                        setUser({loggedIn: false});
                    }
                }
            }
            setIsLoading2(false);
        }

        loadFilters();
        
    }, [char_filters])

    
    const handleAction = async (action, element) => {
        let url = false;
        switch (action) {
            case 'sheet':
                const result = await post("/machine/generate/sheet-pdf/"+ element.id);
                if(result.success){
                    url = getDocumentUrl('/document/machines/'+ element.id +'/'+ getAuthToken() + '/' + result.data.sheet);
                }
                
                if(url){
                    window.open(url);
                }
                break;
            case 'qrcode':
                if(element.hasOwnProperty('qrcode') && element.qrcode){
                    url = getDocumentUrl('/document/machines/'+ element.id +'/'+ getAuthToken() + '/' + element.qrcode);
                }
                
                if(url){
                    window.open(url);
                }
                break;
        
            default:
                if(element.hasOwnProperty('status') && (element.status === 'pending')){
                    url = "/machine/waiting/"+element.id;
                }else{
                    url = "/machine/"+element.id;
                }
                history.push(url);
                break;
        }
        
    }


    const renderStatus = (e) => {
        return getStatus(e, lang);
    }

    const renderState = (e) => {
        return (
            <Badge type={e ? "enable" : "error"}>
                { e ? Text('activated', lang) : Text('disabled', lang)}
            </Badge>
        )
    }
    const renderImage = (e) => {
        if(e){
            return (
                <img src={e} className="object-contain h-24"/>
            )
        }

        return null;
    }

    const config = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "model",
                label: Text('model', lang),
            },
            {
                name: "brand",
                label: Text('brand', lang),
            },
            {
                name: "serial_number",
                label: Text('serial_number', lang),
            },
            {
                name: "park_number",
                label: Text('park_number', lang),
            },
            {
                name: "year",
                label: Text('year', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
            {
                name: "status",
                label: Text('status', lang),
                render: renderStatus
            },
            {
                name: "active",
                label: Text('state', lang),
                render: renderState
            },
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "machine/:id",
                active: true, 
            },
            {
                label: <i className={"icon-doc text-l"}></i>,
                name: "sheet",
                active: "complete", 
                index: "status"
            },
            {
                label: <i className={"icon-qrcode text-l"}></i>,
                name: "qrcode",
                active: "complete", 
                index: "status"
            },
        ],
        error: Text('machine_list_error', lang),
        no_results: Text('no_machine', lang),
        fetchUrl: '/machines',
        handleAction: handleAction,
        params: {
            company: menu.context === 'TOPAZ' ? company : menu.company,
            subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary , 
            site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site ,
            search,
            family,
            brand,
            model,
            product,
            year,
            park_number : parkNumber,
            status : status,
        }
    };

    const uploadMachines = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            machines: data.machines[0],
            site: menu.site ? menu.site : data.site,
        }

        const result = await post("/machines/import", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('form_success_import_machines', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                switch (result.error) {
                    case 'err_02':
                        setErrorMsg(Text('form_error_import_machines_2', lang));
                        break;
                    case 'err_03':
                        setErrorMsg(Text('form_error_import_machines_3', lang));
                        break;
                
                    default:
                        setErrorMsg(Text('form_error_import_machines_1', lang));
                        break;
                }
    
            }
        }
    }

    const downloadMachines = async () => {
        let params = {
            site: menu.site ? menu.site : downloadSite
        }

        const result = getDocumentUrl("/machines/export/"+getAuthToken(), params);
        window.open(result);
    }

    const searchValue = async(charFilters, typeFilters) =>{
        setCharFilters(charFilters)
        setTypeFilters(typeFilters)
    }

    return (
        <ScreenContainer>
            <Modal ref={modalDownloadRef}>
                <span className="text-primary text-m">{Text('popup_export_machine', lang)}</span>
                { menu.site !== '' ? null : (
                    <SelectField 
                        className="mb-4 mt-4" 
                        value={downloadSite}
                        options={{ required: Text('required', lang) }}
                        onChange={(e) => setDownloadSite(e.target.value)}
                        choices={menu.sites}
                    >
                        {Text('site', lang)}
                    </SelectField>
                )}
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalDownloadRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"success"} onPress={() => downloadSite ? downloadMachines() : null}>
                        {Text('export', lang)}
                    </Button>
                </div>
            </Modal>
            <Modal ref={modalRef} onload = {isLoading}>
                <span className="text-primary text-m">{Text('popup_import_machine', lang)}</span>
                <i className={"icon-infos text-m ml-2.5 p-2 border-2 rounded-full"}></i>
                <span className="infos text-primary text-m bg-grid p-2 w-60">{Text('infos_machines_csv', lang)}</span>
                <form onSubmit={handleSubmit(uploadMachines)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => modalRef.current.toggle()}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg}</Message>
                            <FileUpload 
                                name="machines"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.machines}
                                className="mb-6"
                            >
                                {Text('machines', lang)}
                            </FileUpload>
                            { menu.site !== '' ? null : (
                                <SelectField 
                                    name="site"
                                    options={{ required: Text('required', lang) }}
                                    register={register}
                                    error={errors?.site}
                                    className="mb-6"
                                    value={site}
                                    choices={menu.sites}
                                    setValue={setValue}
                                >
                                    {Text('site', lang)}
                                </SelectField>
                            )}
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => modalRef.current.toggle()} disabled={isLoading}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" skin={"success"} isLoading={isLoading}>
                                    {Text('import', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal>
            <div className="flex items-center w-full">
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('machines', lang)}</h1>
                </div>
                { menu.context !== 'TOPAZ' ? (
                    <>
                        { user.role === 'ROLE_ADMIN' ? (
                            <>
                                <Button className="mb-7.5 mr-4" onPress={() => (modalRef.current.toggle(), setSuccessMsg(false))}>
                                    {Text('import_machine', lang)}  
                                </Button>
                                <Button className="mb-7.5 mr-4" onPress={() => menu.site ? downloadMachines() : modalDownloadRef.current.toggle()}>
                                    {Text('export_machine', lang)}  
                                </Button>
                            </>
                        ):null}
                        {user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('add_machine')) ? null : (
                            <Button className="mb-7.5" onPress={() => history.push('/machine/add')}>
                                {Text('add_machine', lang)}  
                            </Button>
                        )}
                    </>
                ) : null}
            </div>
            <div className='flex flex-col'>
                <Box className={isLoading2 ? "is-loading-tab flex mb-4 flex-wrap justify-between" : 'flex mb-4 flex-wrap justify-between'}>
                    { !isLoading2 ? 
                    <>
                        <TextField className="mb-4 w-96" onChange={(e) => setSearch(e.target.value)}>
                            {Text('search', lang)}
                        </TextField>
                        { menu.context !== 'TOPAZ' ? null : (
                            <SelectField 
                                className="mb-4 w-96" 
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                choices={companies}
                                search
                                searchByRequest
                                searchValue={(char) => searchValue(char, 'company')}
                            >
                                {Text('company', lang)}
                            </SelectField>
                        )}
                        { menu.subsidiary !== '' ? null : (
                            <SelectField 
                                className="mb-4 w-96" 
                                value={subsidiary}
                                onChange={(e) => setSubsidiary(e.target.value)}
                                choices={menu.context === 'TOPAZ' ? subsidiaries : menu.subsidiaries}
                                search
                                searchByRequest
                                searchValue={(char) => searchValue(char, 'subsidiary')}
                            >
                                {Text('subsidiary', lang)}
                            </SelectField>
                        )}
                        { menu.site !== '' ? null : (
                            <SelectField 
                                className="mb-4 w-96" 
                                value={site}
                                onChange={(e) => setSite(e.target.value)}
                                choices={menu.context === 'TOPAZ' ? sites : menu.sites}
                                search
                                searchByRequest
                                searchValue={(char) => searchValue(char, 'site')}
                            >
                                {Text('site', lang)}
                            </SelectField>
                        )}
                        <SelectField 
                            name="family"
                            className="mb-4 w-96" 
                            value={family}
                            onChange={(e) => setFamily(e.target.value)}
                            choices={familyFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'family')}
                        >
                            {Text('family', lang)}
                        </SelectField>
                        <SelectField 
                            name="brand"
                            className="mb-4 w-96" 
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                            choices={brandFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'brand')}
                        >
                            {Text('brand', lang)}
                        </SelectField>
                        <SelectField 
                            name="model"
                            className="mb-4 w-96" 
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            choices={modelFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'model')}
                        >
                            {Text('model', lang)}
                        </SelectField>
                        <SelectField 
                            name="product"
                            className="mb-4 w-96" 
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            choices={productFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'product')}
                        >
                            {Text('include_product', lang)}
                        </SelectField>
                        <SelectField 
                            name="park_number"
                            className="mb-4 w-96" 
                            value={parkNumber}
                            onChange={(e) => setParkNumber(e.target.value)}
                            choices={parkNumberFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'park_number')}
                        >
                            {Text('park_number', lang)}
                        </SelectField>
                        <SelectField 
                            name="year"
                            className="mb-4 w-96" 
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            choices={yearFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'year')}
                        >
                            {Text('year', lang)}
                        </SelectField>
                        <SelectField 
                            name="status"
                            className="mb-4 w-96" 
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            choices={statusFilters}
                            search
                        >
                            {Text('status', lang)}
                        </SelectField>

                    </>
                    : null}
                    
                </Box> 
                <Box className="flex flex-1">
                    <Tab {...{config}}/>
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Machines;