export const PROD_DOMAIN = 'https://api.topaz-mypark.pro';
export const LOCAL_DOMAIN =  'https://api.topaz-mypark.pro';
//export const PREPROD_DOMAIN = 'https://api.mypark.site-client.com';
//api.topaz-mypark.pro
export const GRANT_TYPE = 'password';
export const GRANT_TYPE_REFRESH = 'refresh_token';

export const VERSION = '1.5.8';

export const DEBUG = false;
export const MAINTENANCE = false;